<template>
  <ul v-if="totalPage > 1" class="pagination">
    <li :class="{ disabled: internalCurrentPage === 1 }">
      <a href="javascript:;" @click="changePage(1)">首页</a>
    </li>
    <li :class="{ disabled: internalCurrentPage === 1 }">
      <a href="javascript:;" @click="changePage(internalCurrentPage - 1)">«</a>
    </li>

    <li v-show="totalPage <= 5" v-for="(n,index) in totalPage" :key="index+'sm'" :class="{ active: internalCurrentPage === n }">
      <a href="javascript:;" @click="changePage(n)">{{ n }}</a>
    </li>

    <li v-show="totalPage > 5 && internalCurrentPage < 3" v-for="(n,index) in 5" :key="index+'lm'" :class="{ active: internalCurrentPage === n }">
      <a href="javascript:;" @click="changePage(n)">{{ n }}</a>
    </li>

    <li v-show="totalPage > 5 && internalCurrentPage > (totalPage - 2)" v-for="(n,index) in 5" :key="index+'m'" :class="{ active: (internalCurrentPage-5) === n }">
      <a href="javascript:;" @click="changePage( totalPage-5+n)">{{ totalPage-5+n }}</a>
    </li>

    <!-- 34567 -->
    <li v-show="totalPage > 5 && internalCurrentPage >= 3 && internalCurrentPage <= (totalPage - 2)" v-for="(n,index) in 5" :key="index+'bg'" :class="{ active: n === 3}">
      <a href="javascript:;" @click="changePage(internalCurrentPage +n -3)">{{ internalCurrentPage +n -3 }}</a>
    </li>

    <!-- <li v-show="totalPage > 5 && internalCurrentPage > =3" v-for="(n,index) in 5" :key="index" :class="{ active: n === 3}">
      <a href="javascript:;" @click="changePage(internalCurrentPage +n -3)">{{ internalCurrentPage +n -3 }}</a>
    </li> -->
    <!-- <span v-if="totalPage <= 5">
    <li v-for="(n,index) in totalPage" :key="index" :class="{ active: internalCurrentPage === (internalCurrentPage - 3 + n) }">
      <a href="javascript:;" @click="changePage(internalCurrentPage - 3 + n)">{{ internalCurrentPage - 3 + n }}</a>
    </li>
    </span> -->
<!-- <span v-elseif="internalCurrentPage >=3 && internalCurrentPage <= totalPage-3 && totalPage >5"> -->
    <!-- <li v-for="(n,index) in 5" :key="index" :class="{ active: internalCurrentPage === (internalCurrentPage - 1 + n) }">
      <a href="javascript:;" @click="changePage(internalCurrentPage - 1 + n)">{{ internalCurrentPage - 1 + n }}</a>
    </li> -->
    <!-- </span> -->
<!-- <span v-elseif="internalCurrentPage < 3 " >
    <li  v-for="(n,index) in ( totalPage > 5 ? 5 : totalPage)" :key="index" :class="{ active: internalCurrentPage === (internalCurrentPage - 3 + n) }">
      <a href="javascript:;" @click="changePage(internalCurrentPage - 3 + n)">{{ internalCurrentPage - 3 + n }}</a>
    </li>
</span> -->
<!-- 
    <li v-elseif="internalCurrentPage > totalPage-3" v-for="(n,index) in ( totalPage > 5 ? 5 : totalPage)" :key="index" :class="{ active: internalCurrentPage === (internalCurrentPage - 3 + n) }">
      <a href="javascript:;" @click="changePage(internalCurrentPage - 3 + n)">{{ internalCurrentPage - 3 + n }}</a>
    </li> -->

    <li :class="{ disabled: internalCurrentPage === totalPage }">
      <a href="javascript:;" @click="changePage(internalCurrentPage + 1)">»</a>
    </li>
    <li :class="{ disabled: internalCurrentPage === totalPage }">
      <a href="javascript:;" @click="changePage(totalPage)">尾页</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    // 当前页
    currentPage: {
      type: Number,
      default: 1
    },
    // 数据总数
    total: {
      type: Number,
      required: true
    },
    // 每页条数
    pageSize: {
      type: Number,
      default: 10,
      validator: value => value > 0
    },
    // 当前页改变后的回调
    onPageChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      // 组件内的当前页
      internalCurrentPage: 1
    }
  },
  computed: {
    // 总页数
    totalPage() {
      return Math.ceil(this.total / this.pageSize)
    }
  },
  watch: {
    currentPage: {
      immediate: true,
      handler(page) {
        // 更新组件内的当前页，为父组件上 currentPage 的当前值
        this.internalCurrentPage = page
      }
    }
  },
  methods: {
    changePage(page) {
      // console.log(this.internalCurrentPage)
      if (page === this.internalCurrentPage || page < 1 || page > this.totalPage) return
      // 点击的不是当前页时，触发 onPageChange 回调
      this.onPageChange(page)
    }
  }
}
</script>

<style scoped>

</style>