import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 1) {
      if (res.code) {
        return res
      }else {
        return {
          code:0,
          msg:'服务器异常'
        }
      }
    } else {
      return res
    }
  },error => {
    let msg = ''
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 403:
          msg = '当前用户无权限访问'
          break
        default:
          msg = '服务器异常'
      }
    }else {
      msg = '服务器异常'
    }
    return {
      code:0,
      msg:msg
    }
  }
)

export default service
