<template>
  <div class="contact">
      <vue-element-loading
        :active="show"
        spinner="bar-fade-scale"
        color="#FF6700"
        :text="showText"
        :is-full-screen="true"
        />
    <div class="bold-title contact-title">联系我们</div>
    <div class="text-doru"></div>
    <div class="contact-content">
        <div class="row-col row-col-img">
      <img class="contact-img" src="@/assets/contact.png" alt="联系我们" />
    </div>
      <div class="row-col row-col-form">
          <div class="panel-head">
            <h3 class="bold-title panel-title">钣金喷涂装配加工</h3>
          </div>

          <div class="panel-body" data-validator-form>
            <div class="form-group">
              <input type="text" v-model.trim="form.customerName" class="form-control" v-validator.required="{ regex:/^[a-zA-Z\u4e00-\u9fa5]{1,10}$/, error: '联系人要求 1 ~ 10 个字符，只允许输入中文及字母' }" placeholder="联系人">
            </div>
            <div class="form-group">
              <input type="text" v-model.trim="form.customerPhone" class="form-control" v-validator.required="{ regex: /^1[3456789]\d{9}$/, error: '手机号格式错误' }" placeholder="手机号码">
            </div>
            <div class="form-group">
              <textarea class="form-control" v-model.trim="form.msgDescribe" rows="11" v-validator.required="{ regex: /^.{1,500}$/, error: '意向描述要求 1 ~ 500 个单词字符' }" placeholder="加工意向描述"></textarea>
            </div>

            <span @click="onSubmit">
              <button type="submit" class="btn btn-blue btn-sm">
                提交
            </button>
          </span>
          </div>
    </div>
     <div style="clear:both;"></div>
  </div>
  </div>
</template>

<script>
import { saveMessage } from "@/api/common/index"

export default {
  name: 'TheContact',
  data() {
    return {
      form:{
        customerName:'',
        customerPhone:'',
        msgDescribe:'',
        msgType:1
      },
      show:false,
      showText:''
    }
  },
  methods:{
    onSubmit(e) {
      this.$nextTick(() => {
        const target = e.target.type === 'submit' ? e.target : e.target.parentElement

      if (target.canSubmit) {
          this.submit()
        }
      })
    },
    submit(){
        this.show = true
        this.showText = '提交中...'
        saveMessage(this.form)
          .then(res => {
            this.show = false
            if(res.code == 1) {
              this.form = {
                customerName:'',
                customerPhone:'',
                msgDescribe:'',
                msgType:1
              }
            }
            alert(res.msg)
          })
          .catch(err => {
            alert('服务器异常')
            console.log(err);
            this.show = false
          });
      }
  }
}
</script>

<style scoped>
.contact {margin:50px 10% 50px 10%;}
.contact-title {font-size:20px;text-align: center;letter-spacing:4px;padding-bottom:20px;}
.contact /deep/ .form-control {font-size: inherit;}
.contact-content {margin-top:40px;vertical-align: middle;}
.contact-img {width: 100%;margin-left:20px}
.btn-blue {background-color: #0094de;color:#fff;width:100px;}
.row-col {width:50%;display: block;}
.row-col-img {float: right;min-height: 304px;display: flex;}
.row-col-form {float: left;}
.panel-head {padding-left:16px;padding-bottom:8px;}
@media (max-width: 1024px) {
  .contact-content {margin-top:40px;display: block;}
  .row-col {
    display: block;
    width:100%;
  }
  .contact-img {margin-left:0px;margin-bottom:10px;}
  .row-col-img {padding:0 15px;min-height: 0px;display: block;}
}

@media (max-width: 768px) {
  .contact-title{padding-top:20px;}
  .contact {margin:0px;}
  .row-col-img {padding:0 10px;}
  .panel-head{padding:10px;}
  .panel-body{padding:10px;}
}
</style>