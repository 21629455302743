<template>
  <footer class="footer">
    <div class="footer-container">
      <a href="https://www.chinasuperbox.com/" target="_blank" class="content-item">
        <img :src="logo.src" :alt="logo.title" class="big-img">
        <div class="item-text-bold">{{ logo.title }}</div>
      </a>
      <div class="content-item item-text-long">
         <img :src="address.src" :alt="address.title">
        <div class="item-text">{{ address.title }}</div>
      </div>
      <div class="content-item">
        <img :src="phone.src" :alt="phone.title">
        <div class="item-text">{{ phone.title }}</div>
      </div>
      <div class="content-item">
        <img :src="service.src" :alt="service.title">
        <div class="item-text">{{ service.title }}</div>
      </div>
      <div class="content-item">
        <img src="@/assets/code.png" alt="关注我们" class="big-img">
      </div>
    </div>
    <div class="footer-copyright">
      <span>Copyright © 湖北易宝通智能科技有限公司 All Rieserved. <a class="beian" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鄂ICP备2023007627号</a></span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      logo: {
        src:  require("@/assets/logo-white.png"),
        title: '速易宝官网'
      },
      address:{
        src:  require("@/assets/address.png"),
        title: '湖北黄冈浠水经济开发区散花工业园'
      },
      phone:{
        src:  require("@/assets/telphone-more.png"),
        title: '18126461599'
      },
      service:{
        src:  require("@/assets/service-more.png"),
        title: '0755-86001599'
      }
    }
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  /* position:absolute; */
  /* bottom:0; */
  background-color:#043e8b;
}

.footer .footer-container {
  padding:20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-item {
  text-decoration: none;
  width: 20%;
  display: inline-block;
  color:#fff;
  text-align: center;
}

.content-item img {
   width: 20%;
}

.content-item .big-img {
   width: 40%;
}

.content-item .item-text {
  margin-top:5px;
}

.content-item .item-text-bold {
  font-size:16px;
}

.footer-copyright {
  width: 80%;
  text-align: center;
  color: #919aa0;
  margin:0 auto 20px auto;
  padding:20px 0px;
  border-top:1px solid #919aa0;
}

.beian {
  text-decoration: none;
  color: #919aa0;
}

.beian:hover {
  color:#0094de;
}

 @media (max-width: 1024px) {
  .item-text-long {
    margin-top:14px;
  }
 }

 @media (max-width: 768px) {
  .footer .footer-container {
    display: block;
  }
  .content-item {
    display: block;
    width:100%;
  }

  .content-item .big-img {
    width: 80px;
  }

  .content-item .item-text-bold {
    font-size:16px;
  }

  .content-item img {
    width: 24px;
  }

  .content-item .item-text {
    margin:5px 0px;
  }
 }

</style>