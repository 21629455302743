<template>
  <div class="nav-collpose size-12">
    <div class="nav-link">
      <ul class="link-row">
        <li v-for="(item,index) in navLinkList" :key="index" class="link-row-li">
          <router-link :to="item.link" v-if="item.link">{{ item.name }}</router-link>
          <span v-else>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div class="nav-row">
      <div :class="isShowPrev ? 'left-arrow':'arrow-none'"  @click.stop="prev">&nbsp;
        <div class="left-pointer"></div>
      </div>
          <v-touch @swiperight="prev()" @swipeleft="next()">
          <ul class="row-ul" ref="rowUl" v-if="isJump">
            <router-link :to="item.link" v-for="(item,index) in classifyList" :key="index" exact :style="{transform:'translateX(-'+move+'px)'}" class="row-li-link">
              <li ref="rowLi" @click="chooseNav(item,index)"  :class="item.isActive ? 'row-ul-li active' : 'row-ul-li'">{{ item.name }}</li>
            </router-link>
          </ul>
          <ul class="row-ul" ref="rowUl" v-else>
            <li v-for="(item,index) in classifyList" :key="index" ref="rowLi" :style="{transform:'translateX(-'+move+'px)'}" @click="chooseNav(item,index)" :class="index === activeNavIndex?'row-ul-li active':'row-ul-li'">{{ item.name }}</li>
          </ul>
          </v-touch>
      <div :class="isShowNext ? 'right-arrow':'arrow-none'" @click.stop="next">&nbsp;
        <div class="right-pointer"></div>
      </div>
    </div>
      <div style="clear:both;"></div>
  </div>
</template>

<script>
export default {
  name: 'TheNav',
  props: {
    // 轮播项
    navLinkList: {
      type: Array,
      default: () => []
    },
    classifyList: {
      type: Array,
      default: () => []
    },
    // 是否跳转
    isJump: {
      type: Boolean,
      default: true
    },
    // // 轮播延迟
    // delay: {
    //   type: Number,
    //   default: 3000
    // }
  },
  data() {
    return {
      activeNavIndex:0,
      screenWidth: null,
      isShowPrev:false,
      isShowNext:false,
      moveWidth:null,
      move:null,
      maxLength:0
    }
  },
   mounted() { 
        //获取屏幕尺寸
        this.screenWidth = document.body.clientWidth
        this.move = 0
        let _this = this
        this.$once("hook:updated", function() {
          setTimeout(function(){
          _this.maxLength = 0
          _this.moveWidth = _this.$refs.rowLi && _this.$refs.rowLi[0] ? _this.$refs.rowLi[0].offsetWidth:_this.moveWidth
          if(_this.$refs.rowLi) {
            _this.$refs.rowLi.forEach(function(item){
              _this.maxLength += item.offsetWidth
            })
          }
          _this.checkLength(_this.screenWidth)
        },500)
        })
        // setTimeout(function(){
        //   _this.maxLength = 0
        //   _this.moveWidth = _this.$refs.rowLi && _this.$refs.rowLi[0] ? _this.$refs.rowLi[0].offsetWidth:_this.moveWidth
        //   console.log( _this.moveWidth)
        //   if(_this.$refs.rowLi) {
        //     _this.$refs.rowLi.forEach(function(item){
        //       _this.maxLength += item.offsetWidth
        //     })
        //   }
        //   _this.checkLength(_this.screenWidth)
        // },100)
        window.onresize = () => {
            _this.move = 0
            setTimeout(function(){
              _this.moveWidth = _this.$refs.rowLi && _this.$refs.rowLi[0] ? _this.$refs.rowLi[0].offsetWidth:_this.moveWidth
              _this.screenWidth = document.body.clientWidth
              _this.checkLength(_this.screenWidth)
            },500)
            //屏幕尺寸变化
            return (() => {
                // this.moveWidth = this.$refs.rowLi[0] ? this.$refs.rowLi[0].offsetWidth:this.moveWidth
                _this.screenWidth = document.body.clientWidth
            })()
        }

    },
  watch: {
    screenWidth: function () {
      // this.$nextTick(function(){
      //   let that = this
      //   setTimeout(function(){
      //     that.maxLength = 0
      //     that.moveWidth = that.$refs.rowLi && that.$refs.rowLi[0] ? that.$refs.rowLi[0].offsetWidth:that.moveWidth
      //     if(that.$refs.rowLi) {
      //       that.$refs.rowLi.forEach(function(item){
      //         that.maxLength += item.offsetWidth
      //       })
      //     }
      //     that.checkLength(that.screenWidth)
      //   },1000)
      // })
    }
  },
  // updated() {
  //   let that = this
  //   that.maxLength = 0
  //   console.log('aaaa')
  //   setTimeout(function(){
  //     that.moveWidth = that.$refs.rowLi[0] ? that.$refs.rowLi[0].offsetWidth:that.moveWidth
  //     that.$refs.rowLi.forEach(function(item){
  //       that.maxLength += item.offsetWidth
  //     })
  //     that.checkLength(that.screenWidth)
  //   },100)
  // },
  methods: {
    chooseNav(info,index) {
      this.navLinkList[2] ? this.navLinkList[2].name = info.name :  this.navLinkList.push({name:info.name,link:''})
      this.activeNavIndex = index
      this.$emit('changeNav',this.activeNavIndex);
    },
    prev() {
      if(this.isShowPrev == false && this.move <= 0) {
        return;
      }
      this.move -= this.moveWidth;
      if(this.move <= 0) {
        this.isShowPrev = false
      }
      this.isShowNext = true
    },
    next() {
      if(this.isShowNext == false &&  this.$refs.rowUl.offsetWidth + this.move >= this.maxLength) {
        return;
      }
      this.move += this.moveWidth;
      let width = this.$refs.rowUl.offsetWidth + this.move
      if(width >= this.maxLength) {
        this.isShowNext = false
      }
      this.isShowPrev = true     
    },
    checkLength(n) {
      if(this.move <= 0) {
        if(n > 1360) {
          if((this.maxLength + this.moveWidth) <= (n*0.7)) {
            this.isShowPrev = false
            this.isShowNext = false
          }else {
            this.isShowNext = true
            this.isShowPrev = false
          }
        }else if(n > 768) {
           if((this.maxLength + this.moveWidth) <= (n-this.moveWidth)) {
            this.isShowPrev = false
            this.isShowNext = false
          }else {
            this.isShowNext = true
            this.isShowPrev = false
          }
        }
        else {
          if((this.maxLength + this.moveWidth) <= n) {
            this.isShowPrev = false
            this.isShowNext = false
          }else {
            this.isShowNext = true
            this.isShowPrev = false
          }
        }
      }
    }
  }
}
</script>

<style scoped>
 .nav-collpose {
    padding:0 0 0 10%;
    background-color: #f5f5f5;
  }

  .nav-row {
    width:66%;
    float: left;
    white-space: nowrap;
    position: relative;
  }

  .row-ul {
    padding-left:0px; 
    overflow: hidden;
    display: flex;
    /* margin-left:20px; */
  }

  .row-ul-li {
    list-style: none;
    display: inline-block;
    padding:20px 20px;
    cursor: pointer;
    transition:all ease 0.2s;
    /* min-width:100px; */
    text-align: center;
  }

  .row-li-link {
    transition:all ease 0.2s;
  }

  .active {
    background-color:#0094de;
    color:#fff;
  }

  .router-link-active {
    background-color:#0094de;
    color:#fff;
  }

  .nav-link {
    width:34%;
    float: right;
  }

  .link-row {
    padding-left:30px;
  }

  .link-row-li {
    list-style: none;
    display: inline-block;
    padding:20px 10px;
  }

  .link-row-li a {
    color:#525252;
    background-color: #f5f5f5;
    text-decoration: none;
  }

  .link-row-li a:hover {
    color:#009dff;
    text-decoration: none;
  }

  .left-arrow {
    padding:20px 0px;
    /* height: 82px; */
    display: inline-block;
    position: absolute;
    background: #0094de;
    width: 20px;
    cursor: pointer;
    margin-left:-20px;
  }

  .right-arrow {
    padding:20px 0px;
    /* height: 82px; */
    display: inline-block;
    position: absolute;
    background: #0094de;
    width: 20px;
    right:-20px;
    top:0px;
    cursor: pointer;
  }
  
  .left-pointer {
    width: 12px;
    height: 12px;
    position: absolute;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: translate(170%, 170%) rotate(45deg);
    left:-12px;
    cursor: pointer;
    top:0px;
  }

  .arrow-none {
    display: none;
  }

  .right-pointer {
    width: 12px;
    height: 12px;
    position: absolute;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: translate(170%, 170%) rotate(-135deg);
    right:28px;
    top:0px;
    cursor: pointer;
  }

  @media (max-width: 1360px) {
    .nav-row {
      width: 90%;
      padding-right:20px;
    }
    .right-arrow {
      right:0;
    }

    .nav-link{
      width: 100%;
      text-align: right;
    }
}

@media (max-width: 768px) {
    .nav-collpose {
      padding:10px;
    }

    .nav-row {
      width: 100%;
      padding-right:20px;
    }

    .row-ul {
      margin-left:20px;
    }

    .left-arrow {
      margin-left:0px;
    }

    /* .right-pointer {
      right:28px;
    } */
}
</style>