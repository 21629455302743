import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './directives'
import './components'
import store from './store'
// import Message from './plugins/message'
import VueElementLoading from "vue-element-loading";
import 'lib-flexible'
import VueTouch from "vue-touch";
// import VueViewer, { directive as viewerDirective } from 'v-viewer';
import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css'
import BaiduMap from 'vue-baidu-map'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.config.productionTip = false
// Vue.use(Message)

Vue.component("VueElementLoading", VueElementLoading);
Vue.use(VueTouch, {name: "v-touch"});
// 使用Viewer图片预览器
Vue.use(VueViewer)
// 用于图片预览的指令方式调用 在元素上加上会处理元素下所有的图片,为图片添加点击事件,点击即可预览
// Vue.directive('viewer', viewerDirective({debug: true}));

Vue.use(BaiduMap, {
  ak: 'YQiwVrOyoN9C9ZMGkVL2a4jT'
})

router.beforeEach((to,_form,next) => {
    if(to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
},
}).$mount('#app')
