<template>
  <div class="navbar-default">
    <div class="container-content">
      <div class="navbar-head">
        <a href="/" class="navbar-logo">
          <img :src="logo.src" :alt="logo.title">
          <span class="title-info">{{ logo.title }}</span>
        </a>
      </div>
       <div class="content-toggle" @mouseleave="onLeaveSearch" > 
        <div :class="isShow ? 'search-item-block-mobile' :'search-item-hide'"  @mouseleave="onLeaveSearch" >
          <input v-model.trim="keyWord" type="text" class="form-control search-item-mobile" ref="searchInputMobile" @keyup.enter="submit" placeholder="搜索">
          <img src="@/assets/search-black.png" :class="isShow ? 'toggle-search-mobile' :'toggle-search-mobile-hide'" alt="搜索" @click="submit">
        </div>
        <img src="@/assets/search-black.png" alt="搜索" :class="isShow ? 'toggle-search-hide':'toggle-search'" @click="onEnterSearch('mobile')">
        <button type="button" class="navbar-toggle-icon" @click="showNav">
          <span class="sr-only"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
       <div :class="navShow ? 'navbar-mobile-list navbar-mobile-show':'navbar-mobile-list'">
        <ul class="nav-mobile-ul">
           <!-- <li v-for="(item, index) in navList" :class="{ active: index === activeNavIndex }">
            <a href="#" @click="changeNavIndex(index)">{{ item }}</a>
          </li> -->
          <li v-for="(item,index) in navList" :key="index"  :class="{ active: index === activeNavIndex }"  >
            <!-- <a :href="item.link" @click="changeNavIndex(index)">{{ item.name }}</a> -->
            <router-link :exact="index==0 ? true : false" :to="item.link"  @click="changeNavIndex(index)">{{ item.name }}</router-link>
          </li>
        </ul>
        <div class="search-mobile-phone">
            <img src="@/assets/telphone.png" alt="联系方式">
            <span>181-2646-1599</span>
        </div>
      </div>
      <div class="navbar-collapse-list">
        <ul class="nav-ul">
          <li v-for="(item,index) in navList" :key="index" :class="{ active: index === activeNavIndex }">
            <!-- <a :href="item.link" :class="{ active: index === activeNavIndex }">{{ item.name }}</a> -->
             <!-- <router-link :to="item.link">{{ item.name }}</router-link> -->
            <router-link :to="item.link" :exact="index==0 ? true : false"  @click="changeNavIndex(index)">{{ item.name }}</router-link>
          </li>
        </ul>
        <div class="nav-search">
          <div :class="isShow ? 'search-item-block' :'search-item-hide'"  @mouseleave="onLeaveSearch">
            <input v-model.trim="keyWord" type="text" class="form-control search-item" @keyup.enter="submit" ref="searchInput" placeholder="搜索">
            <img src="@/assets/search-black.png" class="toggle-search-item" alt="搜索" @click="submit">
          </div>
          <img src="@/assets/search.png" class="search" alt="搜索" @mouseenter="onEnterSearch">
          <div class="search-phone">
            <img src="@/assets/telphone.png" alt="联系方式">
            <span>181-2646-1599</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TheHeader',
  data() {
    return {
      logo: {
        src:  require("@/assets/logo.png"),
        title: '湖北易宝通智能科技有限公司'
      },
      navList:[
        {
          name:'首页',
          link:{ name: 'Index'}

        },
        {
          name:'产品中心',
          link:{ name: 'Product'}

        },
        {
          name:'加工实力',
          link:{ name: 'Process'}
        },
        {
           name:'成功案例',
          link:{ name: 'CaseIndex'}

        },
         {
           name:'新闻中心',
          link:{ name: 'News'}

        },
        {
          name:'关于我们',
          link:{ name: 'About'}
        }
      ],
      activeNavIndex: '',
      isShow:false,
      navShow:false,
      keyWord:''
    }
  },
  methods: {
    changeNavIndex(index) {
      this.activeNavIndex = index
    },
    onEnterSearch(type='pc') {
      this.isShow = true
      this.$nextTick(() => {
        if(type == 'mobile') {
          this.$refs.searchInputMobile.focus();
        }else {
          this.$refs.searchInput.focus();
        }
      })
    },
    onLeaveSearch() {
      this.isShow = false
    },
    showNav() {
      this.navShow = this.navShow ? false : true;
    },

    submit() {
      if(!this.keyWord) {
        alert('搜索内容不能为空！')
      }else {
        let keyWord = this.keyWord
        
        this.$router.push({name:'Search',query: {keyWord:keyWord}})
      }
    }
  }
}

 var _hmt = _hmt || [];
  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?1691eb972c0eda73d92020ac850de567";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();
</script>

<style scoped>
  .navbar-default {
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:60px;
    background-color:#fff;
    display:flex;
    align-items: center;
    z-index: 10;
    margin-bottom:60px;
    /* border-bottom:1px solid #f0f0f0; */
     /* line-height:60px; */
    /* @media (max-width: 1200px) {

    };
    @media (max-width: 1024px) {

    };
    @media (max-width: 640px){

    }; */
  }

  .container-content {
    width: 100%;
  }

  .navbar-head {
    width:30%;
    /* min-width: 260px; */
    text-align: right;
    display: inline-block;
  }

  .navbar-logo {
    text-decoration: none;
  }

  .navbar-logo img {
    width: 44px;
  }

  .title-info {
    font-size:16px;
    font-weight:bold;
    color:#616060;
    border-left:3px solid #616060; 
    padding-left:6px;
  }

  .navbar-collapse-list{
    width:70%;
    display: inline-block;
    text-align: right;
  }

  .navbar-collapse-list .nav-ul {
    display: inline-block;
    font-size:12px;
  }

  .navbar-collapse-list .nav-ul li{
    display: inline-block;
    padding:10px;
  }

  .navbar-collapse-list .nav-ul li{
    color: #525252;
  }

  .navbar-collapse-list .nav-ul li a:hover{
    color: #1565cf;
    text-decoration: none;
  }

  .router-link-active{
    color: #1565cf;
    font-weight: bold;
    border-bottom:2px solid #1565cf;
    text-decoration: none;
  }

  .nav-search {
    position: relative;
    display: inline-block;
    color: #fff;
    line-height: 60px;
    padding:0px 6px 0px 40px;
     background:
      linear-gradient(110deg, transparent 30px, #1565cf 0)bottom left  ,
      linear-gradient(-45deg, transparent 0px, #1565cf 0)bottom right,
      linear-gradient(-135deg, transparent 0px, #1565cf 0)top right  ,
      linear-gradient(110deg, transparent 40px, #1565cf 0)top left  ;
      background-size: 50% 50% ;
      background-repeat: no-repeat;
  }

  .search-item-block {
    display: block;
    animation: slideInRight 0.2s;
  }

  .search-item-block-mobile {
    display: block;
    animation: slideInRightMobile 0.2s;
  }

  @keyframes slideInRight {
  from {
    transform: translateX(0%);
  }
    to {
    transform: translateX(-30%);
  }
  }
  /* 定义动画的class */
  .slideInRight {
    animation-name: slideInRight;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  @keyframes slideInRightMobile {
  from {
    transform: translateX(50%);
  }
    to {
    transform: translateX(10%);
  }
  }
  /* 定义动画的class */
  .slideInRightMobile {
    animation-name: slideInRightMobile;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  .search-item-hide {
    display: none;
  }

  .search-item {
    display: inline-block;
    position: absolute;
    max-width: 160px;
    top: 14px;
    left: -34%;
    font-size:14px;
    padding-right:28px;
  }

  .search-item-mobile {
    display: inline-block;
    position: absolute;
    max-width: 180px;
    top: 8px;
    right: 80px;
    font-size:14px;
    padding-right:28px;
  }

  .toggle-search-mobile {
    width: 18px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 88px;
    display: block;
  }

  .toggle-search-mobile-hide {
    display:none ;
  }

  .toggle-search-item {
    position: absolute;
    top: 20px;
    left: 24%;
    cursor: pointer;
  }

  .nav-search .search-phone {
    display: inline-block;
    align-items: center;
    font-size:14px;
    border:1px solid #fff;
    border-radius: 14px; 
    line-height: 16px;
    padding:2px 8px;
  }

  .nav-search .search {
    cursor: pointer;
    margin:0px 16px;
  }

  .nav-search .search-phone span {
     vertical-align:middle;
  }

  .nav-search img {
    width: 20px;
  }

  .content-toggle {
    display: none;
  }

  .navbar-mobile-list {
    display: none;
  }
  
  @media (max-width: 1360px) {
    .navbar-default {
      height:50px;
    }

    .navbar-logo img {
      width: 33px;
    }

    .title-info {
      font-size:12px;
    }

    .navbar-collapse-list .nav-ul {
      font-size:8px;
      padding-left:2px;
    }

    .nav-search {
      line-height: 50px;
      background:
        linear-gradient(110deg, transparent 31.5px, #1565cf 0)bottom left  ,
        linear-gradient(-45deg, transparent 0px, #1565cf 0)bottom right,
        linear-gradient(-135deg, transparent 0px, #1565cf 0)top right  ,
        linear-gradient(110deg, transparent 40px, #1565cf 0)top left  ;
        background-size: 50% 50% ;
        background-repeat: no-repeat;
    }

     .search-item {
        top: 8px;
        font-size:10px;
        left: -52%;
      }

    .toggle-search-item {
      top: 18px;
      left: 26%;
    }

    .nav-search .search-phone {
      font-size:8px;
      line-height: 8px;
    }

    .nav-search .search {
      margin:0px 12px;
    }

    .nav-search img {
      width: 16px;
    }
  }

  @media (max-width: 1024px) {
    .navbar-head {
      width:70%;
      /* line-height: 50px; */
      /* min-width: 260px; */
      padding-left:30px;
      text-align: left;
      /* display: inline-block; */
    }
   
    .navbar-mobile-list {
      /* display: block; */
      position:fixed;
      width: 60%;
      background-color: #fff;
      height: 100vh;
      border-top:1px solid #ccc;
      top: 50px;
      right: 0;
      z-index: 100000;
    }

    .navbar-mobile-show {
      display: block;
      animation: navRight 0.5s;
    }

    @keyframes navRight {
    from {
      transform: translateX(100%);
    }
      to {
      transform: translateX(0%);
    }
    }
    /* 定义动画的class */
    .slideInRight {
      animation-name: navRight;
      animation-duration:0.5s;
      animation-fill-mode: both;
    }

    .nav-mobile-ul {
      padding:0;
    }

    .nav-mobile-ul li {
      list-style: none;
      /* line-height: 40px; */
      border-bottom:1px solid #f0f0f0;
      text-align: center;
      font-size: 12px;
    }

    .nav-mobile-ul li a {
      display: inline-block;
      margin:14px 0px;
      /* color: #525252; */
      text-decoration: none;
    }

    .nav-mobile-ul li .active{
      color: #1565cf;
      font-weight: bold;
      border-bottom:2px solid #1565cf;
      text-decoration: none;
    }

    /* .navbar-collapse-list .nav-ul li .router-link-active{
      color: #1565cf;
      font-weight: bold;
      border-bottom:2px solid #1565cf;
      text-decoration: none;
    } */


    .nav-mobile-ul li a:hover {
      /* color: #1565cf; */
      /* font-weight: bold; */
      /* border-bottom:2px solid #1565cf;
      text-decoration: none; */
    }

    .search-mobile-phone {
      background-color: #1565cf;
      text-align: center;
      margin:10px 20px;
      font-size: 14px;
      padding:10px 0px;
    }

    .search-mobile-phone img {
      width: 18px;
    }

    .search-mobile-phone span {
      vertical-align: middle;
      color:#fff;
    }

    .navbar-collapse-list {
      /* font-size:8px;
      padding-left:4px; */
      display: none;
    }

    .nav-search {
       display: none;
      /* line-height: 50px;
      background:
        linear-gradient(110deg, transparent 31.5px, #1565cf 0)bottom left  ,
        linear-gradient(-45deg, transparent 0px, #1565cf 0)bottom right,
        linear-gradient(-135deg, transparent 0px, #1565cf 0)top right  ,
        linear-gradient(110deg, transparent 40px, #1565cf 0)top left  ;
        background-size: 50% 50% ;
        background-repeat: no-repeat; */
    }

    /* .nav-search .search-phone {
      font-size:8px;
      line-height: 8px;
    }

    .nav-search .search {
      margin:0px 12px;
    }

    .nav-search img {
      width: 14px;
    } */

    .content-toggle {
      display: inline-block;
      width: 30%;
      text-align: right;
      padding-right:30px;
    }

    .content-toggle .toggle-search {
      display: inline-block;
      width: 18px;
      margin-right:10px;
      cursor: pointer;
    }

    .content-toggle .toggle-search-hide {
      display: none;
    }

    .navbar-toggle-icon {
      position: relative;
      /* float: right; */
      padding: 6px; 
      margin-right: 4px;
      margin-top: 2px;
      margin-bottom: 2px;
      background-color: transparent;
      background-image: none;
      border: 2px solid #ddd;
      border-radius: 4px;
      vertical-align: middle;
    }

    .navbar-toggle-icon:hover {
      background-color: #ccc;
    }

    .sr-only {
      position: absolute;
      width: 8px;
      height: 2px;
      padding: 0;
      margin: -2px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    .navbar-default .navbar-toggle-icon .icon-bar {
      background-color: #888;
    }
    .navbar-toggle-icon .icon-bar + .icon-bar {
      margin-top: 1px;
    }
    .navbar-toggle-icon .icon-bar {
      display: block;
      width:24px;
      height: 2px;
      border-radius: 2px;
      margin:4px 0px;
    }
  }
</style>