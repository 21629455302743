import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [

  {
    path: "/",
    name: 'Index',
    component: () => import('@/views/index'),
    meta: {
      title: '钣金加工-阴极电泳-主柜钣金装配「易宝通」',
      content:{
        keywords:'钣金加工,阴极电泳加工,钣金装配',
        description:'湖北易宝通智能科技有限公司致力于：钣金加工27年，用于2条4.0柔性生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等。'
      }
    }
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [{
  //     path: 'dashboard',
  //     name: 'Dashboard',
  //     component: () => import('@/views/dashboard/index'),
  //     meta: { title: '首页', icon: 'icon-dashboard' }
  //   }]
  // }
  {
    path: "/product",
    redirect: '/product/list',
    name: 'Product',
    component: () => import('@/views/product/index'),
    // meta: {
    //   title: '产品中心'
    // },
    children: [
      {
        path: "/product/list",
        name: 'ProductList',
        component: () => import('@/views/product/list'),
        meta: {
          title: '钣金零件加工-钣金弯折-钣金成型「易宝通」',
          content:{
            keywords:'钣金零件加工,钣金弯折,钣金成型',
            description:'湖北易宝通智能科技有限公司专业承接：钣金零件加工、钣金弯折一套成型、可接复杂多规格，支持来样来图定制，详情请来电咨询。'
          }
        }
      },
      {
        path: "/product/list/3",
        name: 'ProductListSheet',
        component: () => import('@/views/product/list'),
        meta: {
          title: '钣金零件加工-钣金弯折-钣金成型「易宝通」',
          content:{
            keywords:'钣金零件加工,钣金弯折,钣金成型',
            description:'湖北易宝通智能科技有限公司专业承接：钣金零件加工、钣金弯折一套成型、可接复杂多规格，支持来样来图定制，详情请来电咨询。'
          }
        }
      },
      {
        path: "/product/list/4",
        name: 'ProductListElect',
        component: () => import('@/views/product/list'),
        meta: {
          title: '电泳加工-阴极电泳-阳极电泳「易宝通」',
          content:{
            keywords:'电泳加工,阴极电泳,阳极电泳',
            description:'湖北易宝通致力于电泳加工27年，拥有先进的加工设备和平均工龄10年以上的技术人员，对电泳加工有丰富的实战经验，欢迎来电咨询。'
          }
        }
      },
      {
        path: "/product/list/5",
        name: 'ProductListSpray',
        component: () => import('@/views/product/list'),
        meta: {
          title: '喷涂加工-静电喷涂-钣金喷涂「易宝通」',
          content:{
            keywords:'喷涂加工,静电喷涂,钣金喷涂',
            description:'湖北易宝通专注于：各类钣金静电喷涂，采用进口喷粉设备，安全防爆，漆面光滑平整，美观和防腐蚀同时兼备，承接各类喷涂业务详情来电咨询。'
          }
        }
      },
      {
        path: "/product/list/6",
        name: 'ProductListComplete',
        component: () => import('@/views/product/list'),
        meta: {
          title: '整机加工-智能柜钣金加工-主柜组装「易宝通」',
          content:{
            keywords:'整机加工,智能柜钣金,主柜组装',
            description:'湖北易宝通拥有丰富的钣金加工和组装经验，熟悉各种材料和工艺要求,每年为上市企业出货量1200万台，品质值得保证。'
          }
        }
      },
      {
        path: "/product/list/:productId(\\d+)",
        name: 'ProductListParams',
        component: () => import('@/views/product/list'),
        meta: {
          title: '钣金零件加工-钣金弯折-钣金成型「易宝通」',
          content:{
            keywords:'钣金零件加工,钣金弯折,钣金成型',
            description:'湖北易宝通智能科技有限公司专业承接：钣金零件加工、钣金弯折一套成型、可接复杂多规格，支持来样来图定制，详情请来电咨询。'
          }
        }
      },
      {
        path: "/product/detail/:productId(\\d+)",
        name: 'ProductDetail',
        component: () => import('@/views/product/detail'),
        meta: {
          title: '钣金加工-阴极电泳-主柜钣金装配「易宝通」',
          content:{
            keywords:'钣金加工,阴极电泳加工,钣金装配',
            description:'湖北易宝通智能科技有限公司，承接各类钣金加工、电泳防锈处理、表面喷涂、整机装配服务，加工工艺和品质值得信赖。'
          }
        }
      },
    ],
  },
  {
    path: "/process",
    name: 'Process',
    component: () => import('@/views/process/index'),
    meta: {
      title: '激光切割-钣金折弯-落冲成型-阴极电泳加工「易宝通」',
      content:{
        keywords:'激光切割,钣金折弯,落冲成型,阴极电泳加工',
        description:'湖北易宝通专业承接钣金类加工拥有：激光切割、弯折一体、落冲成型、气动压铆机、阴极电泳等上百种进口加工设备，欢迎来电咨询。'
      }
    }
  },
  {
    path: "/case",
    name: 'CaseIndex',
    component: () => import('@/views/case/index'),
    meta: {
      title: '钣金加工-阴极电泳-主柜钣金装配「易宝通」',
      content:{
        keywords:'钣金加工,阴极电泳加工,钣金装配',
        description:'湖北易宝通专业的钣金加工厂商，为上市企业每年生产加工1200万套智能箱柜，项目覆盖全国40多个省市区，10000+成功案例，品质值得保障。'
      }
    }
  },
  {
    path: "/case/:caseId(\\d+)",
    name: 'CaseDetail',
    component: () => import('@/views/case/index'),
    meta: {
      title: '钣金加工-阴极电泳-主柜钣金装配「易宝通」',
      content:{
        keywords:'钣金加工,阴极电泳加工,钣金装配',
        description:'湖北易宝通专业的钣金加工厂商，为上市企业每年生产加工1200万套智能箱柜，项目覆盖全国40多个省市区，10000+成功案例，品质值得保障。'
      }
    }
  },
  {
    path: "/news",
    name: 'News',
    redirect: '/news/list',
    component: () => import('@/views/news/index'),
    children: [
      {
        path: "/news/list",
        name: 'NewsList',
        component: () => import('@/views/news/list'),
        meta: {
          title: '钣金加工公司新闻-钣金工艺动态-湖北易宝通钣金加工',
          content:{
            keywords:'钣金加工,钣金工艺',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
      {
        path: "/news/list/5",
        name: 'NewsListCompany',
        component: () => import('@/views/news/list'),
        meta: {
          title: '钣金加工公司新闻-钣金工艺动态-湖北易宝通钣金加工',
          content:{
            keywords:'钣金加工,钣金工艺',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
      {
        path: "/news/list/6",
        name: 'NewsListIndustry',
        component: () => import('@/views/news/list'),
        meta: {
          title: '阴极电泳行业咨询-电泳防锈加工-湖北易宝通钣金加工',
          content:{
            keywords:'电泳防锈加工,阴极电泳',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
      {
        path: "/news/list/7",
        name: 'NewsListTechnical',
        component: () => import('@/views/news/list'),
        meta: {
          title: '钣金电泳加工-钣金表面处理-钣金整机装配「易宝通」',
          content:{
            keywords:'钣金电泳加工,钣金表面处理,钣金整机装配',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
      {
        path: "/news/list/:newsId(\\d+)",
        name: 'NewsListParams',
        component: () => import('@/views/news/list'),
        meta: {
          title: '钣金电泳加工-钣金表面处理-钣金整机装配「易宝通」',
          content:{
            keywords:'钣金电泳加工,钣金表面处理,钣金整机装配',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
      // {
      //   path: "/news/detail",
      //   name: 'NewsInfo',
      //   component: () => import('@/views/news/detail'),
      //   redirect: '/'
      // },
      {
        path: "/news/detail/:newsId(\\d+)",
        name: 'NewsDetail',
        component: () => import('@/views/news/detail'),
        meta: {
          title: '钣金电泳加工-钣金表面处理-钣金整机装配「易宝通」',
          content:{
            keywords:'钣金电泳加工,钣金表面处理,钣金整机装配',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
    ],
  },
  {
    path: "/about",
    name: 'About',
    redirect: '/about/index',
    component: () => import('@/views/about/index'),
    children: [
      {
        path: "/about/index",
        name: 'AboutIndex',
        component: () => import('@/views/about/info'),
        meta: {
          title: '钣金加工厂家-电泳加工公司-整机装配厂商「易宝通」',
          content:{
            keywords:'钣金加工厂家,电泳加工公司,整机装配厂商',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
      {
        path: "/about/honor",
        name: 'AboutHonor',
        component: () => import('@/views/about/honor'),
        meta: {
          title: '荣誉资质湖北易宝通钣金加工厂家',
          content:{
            keywords:'钣金加工厂家',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
      {
        path: "/about/partner",
        name: 'AboutPartner',
        component: () => import('@/views/about/partner'),
        meta: {
          title: '合作伙伴湖北易宝通钣金加工厂家',
          content:{
            keywords:'钣金加工厂家',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
      {
        path: "/about/contact",
        name: 'AboutContact',
        component: () => import('@/views/about/contact'),
        meta: {
          title: '联系我们湖北易宝通钣金加工厂家',
          content:{
            keywords:'钣金加工厂家',
            description:'湖北易宝通拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等，有标准化的钣金、喷涂、装配等生产制造车间五栋。'
          }
        }
      },
    ]
  },
  {
    path: "/search",
    name: 'Search',
    component: () => import('@/views/search/index'),
    meta: {
      title: '钣金加工-阴极电泳-主柜钣金装配「易宝通」',
      content:{
        keywords:'钣金加工,阴极电泳加工,钣金装配',
        description:'湖北易宝通智能科技有限公司致力于：钣金加工27年，用于2条4.0柔性生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等。'
      }
    }
  },
  {
    path: '*',
    redirect: '/'
  },
]

const router = new Router({
  mode: 'history',
  // base: '/syb_questionnaire/',
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // console.log(to)
    if (to.hash) {
      // 有锚点时，滚动到锚点
      return { selector: to.hash }
    } else if (savedPosition) {
      // 有保存位置时，滚动到保存位置
      return savedPosition
    } else {
      // 默认滚动到页面顶部
      return { x: 0, y: 0 }
    }
  },
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  //  从其他地方访问是否有这个地址
  // const app = router.app
  // app.$message.hide()
  if (to.meta.content) {
		let head = document.getElementsByTagName('head');
		let meta = document.createElement('meta');
		document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
		document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
		meta.content = to.meta.content;
		head[0].appendChild(meta)

	}
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (to.matched.length == 0) {
    from.path ? next({ name: from.name }) : next({ name: 'Index' })
  }
  next();
});

router.afterEach(() => {
  //导航被确认后 添加消息提示信息
  // const app = router.app
  // const showMsg = to.params.showMsg
  // console.log(to.params)
  // if(showMsg) {
  //   if(typeof showMsg === 'string') {
  //     app.$message.show(showMsg)
  //   }
  // }

  // window.scrollTo(0,0);
})

export default router