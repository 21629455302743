import Vue from 'vue'
import Message from './Message'
import Slider from './Slider'
import TheContact from './TheContact'
import TheNav from './TheNav'
import Pagination from './Pagination'

const components = {
  Message,
  // 添加到对象以便在循环中进行注册
  Slider,
  TheContact,
  TheNav,
  Pagination,
}

for (const [key, value] of Object.entries(components)) {
  Vue.component(key, value)
}